import styled from 'styled-components'
import { media } from '../helpers/media'

export const Container = styled.section`
	padding-top: var(--spacing-extra-large);
	padding-bottom: var(--spacing-extra-large);
	padding-left: var(--spacing-large);
	padding-right: var(--spacing-large);
`

Container.Inner = styled.div`
	margin-left: auto;
	margin-right: auto;
	max-width: var(--max-width-8);

	${props => props.narrow && `
		max-width: var(--max-width-7);
	`}
`