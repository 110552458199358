import { css } from 'styled-components'

const sizes = {
  ns: 'screen and (min-width: 30em)',
  m: 'screen and (min-width: 30em) and (max-width: 60em)',
  l: 'screen and (min-width: 60em)',
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${sizes[label]} {
      ${css(...args)}
    }
  `
  return acc
}, {})