import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { media } from '../helpers/media'

const Wrapper = styled.div`
  
`

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: calc(-1*var(--spacing-extra-small));
  margin-left: calc(-1*var(--spacing-extra-extra-small));
  margin-right: calc(-1*var(--spacing-extra-extra-small));
  padding-left: 0;
`

List.Item = styled.li`
  margin-bottom: var(--spacing-extra-small);
  padding-left: var(--spacing-extra-extra-small);
  padding-right: var(--spacing-extra-extra-small);

`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: var(--width-2);
  height: var(--height-2);
  background: transparent;
  color: var(--mid-gray);
  line-height: 1;
  cursor: pointer;
`


const Symbols = ({addSymbol}) => {
	//console.log()
  const symbols = ['♫', '☂', '☀', '✈', '☎', '♥', '✪', '★', '☆', '✚', '✘', '✓', '▼', '▲', '◄', '►', '&', '/', '%', '–', '*', '#', '+', '€', '❤️', '✅', '📊', '📈', '📉', '➡️', 'lll➤']
	
  return (
  	<Wrapper>
      <List>
  		{symbols.map(symbol => (
        <List.Item><Button onClick={addSymbol}>{symbol}</Button></List.Item>
      ))}
      </List>
  	</Wrapper>
  )
}

export default Symbols