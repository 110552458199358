import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  isEmpty,
} from 'lodash'
import insertTextAtCursor from 'insert-text-at-cursor'

import styled from 'styled-components'
import { media } from '../helpers/media'

import Bar from './Bar'
import Symbols from './Symbols'

const Snippet = styled.div`
  margin-bottom: var(--spacing-large);
  box-shadow: var(--box-shadow-4);
  border: var(--silver) solid 1px;
  border-radius: var(--border-radius-1);
  padding: var(--spacing-large);

  font-family: var(--sans-serif);
  line-height: var(--line-height-title);
`

Snippet.Inner = styled.div`
  max-width: var(--max-width-6);
`

Snippet.Title = styled.div`
  margin-bottom: var(--spacing-small);
  color: var(--dark-blue);
  font-size: var(--font-size-4);
`

Snippet.Url = styled.div`
  margin-bottom: var(--spacing-extra-small);
  color: var(--dark-green);
  font-size: var(--font-size-6);
`

Snippet.Description = styled.div`
  color: var(--dark-gray);
  font-size: var(--font-size-6);
`

const Form = styled.form`
`

Form.Group = styled.div`
  margin-bottom: var(--spacing-medium);
  
`

Form.Label = styled.span`
  display: block;
  color: var(--mid-gray);
  font-family: var(--sans-serif);
  font-size: var(--font-size-6);
  line-height: var(--line-height-copy);
  font-weight: bold;
`

Form.Info = styled.div`
  display: block;
  float: right;
  color: var(--gray);
  font-family: var(--sans-serif);
  font-size: var(--font-size-5);
  line-height: var(--line-height-solid);
  font-weight: normal;
`

Form.Input = styled.input`
  display: block;
  border: var(--light-silver) solid 1px;

  width: 100%;

  padding: var(--spacing-medium);

  color: var(--mid-gray);

  font-family: var(--sans-serif);
  font-size: var(--font-size-5);
  line-height: var(--line-height-copy);

`


const App = ({}) => {
  const maxCountTitle = 70
  const maxCountDescription = 160
  const boundariesTitle = [
    // respect order
    {
      min: 0,
      type: 'info',
    },
    {
      min: 57,
      type: 'warning',
    },
    {
      min: 70,
      type: 'danger',
    },
  ]
  const boundariesDescription = [
    // respect order
    {
      min: 0,
      type: 'info',
    },
    {
      min: 129,
      type: 'warning',
    },
    {
      min: 160,
      type: 'danger',
    },
  ]     

  const [tempUrl, setTempUrl] = useState('')
  const [url, setUrl] = useState('')
  const [title, setTitle] = useState('Mehr Erfolg für Ihr Online-Projekt! - Projectival - Sascha Klapetz | Projectival')
  const [snippetTitle, setSnippetTitle] = useState(title)
  const [countTitle, setCountTitle] = useState(title.length)
  const [description, setDescription] = useState('► 14+ Jahre Online-Marketing & Webentwicklung in Köln / Bonn: ✓ Website-Konzept ✓ UX/SEO-Audit ✓ Landingpage ✓ Leadgenerierung ► Leistungen ansehen')
  const [countDescription, setCountDescription] = useState(description.length)
  const [snippetDescription, setSnippetDescription] = useState(description)
  const [keyword, setKeyword] = useState('')

  const [page, setPage] = useState(false)

  useEffect(() => { fetchData() }, [url])

  const updatePage = () => {
    fetchData();
  }

  useEffect(() => { updateMeta() }, [page])

  async function fetchData() {
    // fetch polyfill?
    if (!tempUrl)
      return
    const proxyUrl = `https://cors-proxy-server-53.herokuapp.com/${tempUrl}`
    const resp = await fetch(proxyUrl)
    const text = await resp.text()
    setPage(text)
  }

  const updateMeta = () => {
    
    const text = page

    //console.log(text)

    // validate text
    if (isEmpty(text)) return
    const parser = new DOMParser()
    const doc = parser.parseFromString(text, `text/html`)
    // validate doc?
    //console.log(doc)

    const getMeta = (doc, selector, attribute) => {
      const el = doc.querySelector(selector)
      if (!el) return
      if (attribute)
         return el.getAttribute(attribute)
      else
        return el.textContent
    }

    const metaTitle = getMeta(doc, 'title')
    const metaDescription = getMeta(doc, 'meta[name="description"]', `content`)

    if (metaTitle)
        setTitle(metaTitle)
    if (metaDescription)
        setDescription(metaDescription)
    setUrl(tempUrl)
  }

  useEffect(() => { updateKeyword() })

  const updateKeyword = () => {

    const boldKeyword = (string, keyword) => {
      if (!keyword) return string
      let array = string.split(` `)
      array = array.map(string => new RegExp(`\\b${keyword}\\b`, 'i').test(string) ? `<strong>${string}</strong>` : string)
      string = array.join(` `)
      return string
    }

    setSnippetTitle(boldKeyword(title, keyword))
    //setSnippetTitle(title)
    setSnippetDescription(boldKeyword(description, keyword))
  }

  useEffect(() => { truncateMeta() }, [snippetTitle, snippetDescription])
  const truncateMeta = () => {
    const truncateString = (string, length) => (string.length > length) ? string.substr(0, length-1) + '&hellip;' : string
    console.log(truncateString(snippetTitle, maxCountTitle))
    setSnippetTitle(truncateString(snippetTitle, maxCountTitle))
    setSnippetDescription(truncateString(snippetDescription, maxCountDescription))
  }

  useEffect(() => { updateCount() }, [title, description])

  const updateCount = () => {
    setCountTitle(title.length)
    setCountDescription(description.length)
  }

  const getBoundaryType = (count, boundaries) => {
    let type = ''
    boundaries.map(boundary => {
      if (count >= boundary.min) type = boundary.type
    })
    return type
  }

  const [lastInput, setLastInput] = useState(false)
  let titleInput = React.createRef()
  const addSymbol = (e) => {
    e.preventDefault()
    const el = lastInput ? lastInput : titleInput.current
    console.log(e.target.textContent, el)
    insertTextAtCursor(el, e.target.textContent)
  }  

  return (
  <>
    <Form
      onSubmit={e => {
        e.preventDefault() 
        updatePage()
      }}
    >
      <Form.Group>
        <Form.Label>Url</Form.Label>
        <Form.Input value={tempUrl} onChange={e => setTempUrl(e.target.value)} />
      </Form.Group>
    </Form>

    <Snippet>
      <Snippet.Inner>
        <Snippet.Title dangerouslySetInnerHTML={{__html: snippetTitle}} />
        <Snippet.Url>{url}</Snippet.Url>
        <Snippet.Description dangerouslySetInnerHTML={{__html: snippetDescription}} />
      </Snippet.Inner>
    </Snippet>

    <Form>
      <Form.Group>
        <Form.Label>Title <Form.Info>{countTitle} / {maxCountTitle} Zeichen{/* (x px)*/}</Form.Info></Form.Label>
        <Form.Input value={title} onChange={e => setTitle(e.target.value)} onFocus={e => setLastInput(e.target)} ref={titleInput} />
        <Bar percentage={countTitle/maxCountTitle} type={getBoundaryType(countTitle, boundariesTitle)} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Description <Form.Info>{countDescription} / {maxCountDescription} Zeichen</Form.Info></Form.Label>
        <Form.Input as="textarea" rows="4" value={description} onChange={e => setDescription(e.target.value)} onFocus={e => setLastInput(e.target)} />
        <Bar percentage={countDescription/maxCountDescription} type={getBoundaryType(countDescription, boundariesDescription)} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Sonderzeichen</Form.Label>
        <Symbols addSymbol={addSymbol} />
      </Form.Group>
      <Form.Group>
        <Form.Label>Keyword</Form.Label>
        <Form.Input value={keyword} onChange={e => setKeyword(e.target.value)} />
      </Form.Group>
    </Form>

    
    
  </>
)}

App.propTypes = {
}

App.defaultProps = {
}

export default App
