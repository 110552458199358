import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { media } from '../helpers/media'

const Wrapper = styled.div`
  display: block;
  height: calc(var(--height-1)/4);

  background-color: var(--light-gray);
`

const Inner = styled.div`
  display: block;
  height: 100%;

  background-color: var(--silver);

  transition: width .5s;

  ${props => props.type=='info' && `
  	background-color: var(--blue);
 	`}

 	${props => props.type=='warning' && `
  	background-color: var(--yellow);
 	`}

 	${props => props.type=='danger' && `
  	background-color: var(--red);
 	`}
`

const Bar = ({percentage, type}) => {
	//console.log(percentage, type)
	if (percentage > 1)
		percentage = 1
  return (
  	<Wrapper>
  		<Inner css={`width: ${percentage*100}%;`} type={type}></Inner>
  	</Wrapper>
  )
}

export default Bar