import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import {
  Container,
} from '../components/styled'

import App from '../components/App'

export default ({data}) => {
  //console.log(data)
  return (
  <Layout>
    <Container>
      <Container.Inner narrow>
        {/*<h1>SERP Snippet Tool</h1>*/}
        <App />
      </Container.Inner>
    </Container>
  </Layout>
)}
